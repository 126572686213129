import React, { Component } from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import CtaContact from "../partials/CtaContact";
import Footer from "../partials/Footer";
import axios from "axios";

const InitalState = {
  contactform: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    subject: "",
    country: "",
    message: "",
    isAgreeChecked: false,
  },
  contactform_validation: {
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    subject: false,
    country: false,
    message: false,
    isAgreeChecked: false,
  },
  inputToValidate: {
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    subject: false,
    country: false,
    message: false,
    isAgreeChecked: false,
  },
};

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactform: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        isAgreeChecked: false,
      },
      contactform_validation: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        subject: false,
        message: false,
        isAgreeChecked: false,
      },
      inputToValidate: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        subject: false,
        message: false,
        isAgreeChecked: false,
      },
    };
  }
  onSendButton = (e) => {
    e.preventDefault();
    console.log(
      "🚀 ~ file: Contact.js ~ line 14 ~ Contact ~ re",
      e.target,
      this.state.contactform
    );
    let validated = null;

    Object.values(this.state.contactform_validation).forEach((current) => {
      if (validated === false) {
        return true;
      } else {
        if (current === true) {
          validated = true;
        } else if (current === false) {
          validated = false;
        }
      }
    });
    console.log(validated);
    if (validated === false) {
    } else {
      axios({
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? "http://localhost:3000/engsys/contact_form"
            : "https://apiv1.reserv4me.com/engsys/contact_form",
        headers: {
          "Access-Control-Allow-Origin":
            process.env.NODE_ENV === "development"
              ? "http://localhost:3000"
              : "https://apiv1.reserv4me.com",
          "Content-Type": "application/json",
          Authorization: "internalvar.apikey",
        },
        data: {
          contactform: this.state.contactform,
        },
        withCredentials: true,
      }).then((resp) => {
        //console.log("🚀 ~ file: Contact.js ~ line 84 ~ Contact ~ resp", resp);
        //this.resetState();
        window.location.reload();
      });
    }
  };
  onFormValueChange = (e) => {
    let tempvar = e.target;
    let validation = this.ValidatorforInput(tempvar.id, tempvar.value);
    this.setState((prevState) => {
      const list = prevState.contactform;
      const listvalidation = prevState.contactform_validation;
      const listallowvalidation = prevState.inputToValidate;
      if (tempvar.id === "isAgreeChecked") {
        list[tempvar.id] = tempvar.checked;
        listvalidation[tempvar.id] = tempvar.checked;
        listallowvalidation[tempvar.id] = validation;
        return { contactform: list };
      } else {
        list[tempvar.id] = tempvar.value;
        listvalidation[tempvar.id] = validation;
        listallowvalidation[tempvar.id] = true;
        return {
          contactform: list,
          contactform_validation: listvalidation,
          inputToValidate: listallowvalidation,
        };
      }
    });
  };

  ValidatorforInput = (type, value) => {
    if (type === "email") {
      let validatorRegExp = new RegExp(
        /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (validatorRegExp.test(value)) {
        return true;
      } else {
        return false;
      }
    } else if (type === "isAgreeChecked") {
      return true;
    } else if (type === "phone") {
      if (value !== "") {
        return true;
      } else {
        return false;
      }
    } else {
      if (value !== "") {
        return true;
      } else {
        return false;
      }
    }
  };

  resetState = () => {
    console.log("reserting");
    this.setState((prevState) => ({
      contactform: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        subject: "",

        message: "",
        isAgreeChecked: false,
      },
      contactform_validation: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        subject: false,

        message: false,
        isAgreeChecked: false,
      },
      inputToValidate: {
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        subject: false,

        message: false,
        isAgreeChecked: false,
      },
    }));
  };

  render() {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          {/*  Page illustration */}
          <div
            className="relative max-w-6xl mx-auto h-0 pointer-events-none"
            aria-hidden="true">
            <PageIllustration />
          </div>

          <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                {/* Page header */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                  <h1 className="h1 mb-4" data-aos="fade-up">
                    How can we help you?
                  </h1>
                  <p
                    className="text-xl text-gray-400"
                    data-aos="fade-up"
                    data-aos-delay="200">
                    We have custom plans to power your business. Tell us your
                    needs, and we’ll contact you shortly.
                  </p>
                </div>

                {/* Contact form */}
                <form className="max-w-xl mx-auto">
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="first_name">
                        First Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="first_name"
                        type="text"
                        className={`form-input w-full text-gray-300 
                        ${
                          this.state.inputToValidate.first_name === false
                            ? ""
                            : this.state.inputToValidate.first_name === true &&
                              this.state.contactform_validation.first_name ===
                                false
                            ? "border-red-500"
                            : ""
                        } focus:border-blue-500 bg-black`}
                        placeholder="Enter your first name"
                        required
                        value={this.state.first_name}
                        onChange={this.onFormValueChange}
                      />
                      <p
                        className="text-red-500 text-sm mt-2"
                        hidden={
                          this.state.inputToValidate.first_name === false
                            ? true
                            : this.state.contactform_validation.first_name
                        }>
                        This field is required
                      </p>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="last_name">
                        Last Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="last_name"
                        type="text"
                        className={`form-input w-full text-gray-300 
                        ${
                          this.state.inputToValidate.last_name === false
                            ? ""
                            : this.state.inputToValidate.last_name === true &&
                              this.state.contactform_validation.last_name ===
                                false
                            ? "border-red-500"
                            : ""
                        } focus:border-blue-500 bg-black`}
                        placeholder="Enter your last name"
                        required
                        value={this.state.last_name}
                        onChange={this.onFormValueChange}
                      />
                      <p
                        className="text-red-500 text-sm mt-2"
                        hidden={
                          this.state.inputToValidate.last_name === false
                            ? true
                            : this.state.contactform_validation.last_name
                        }>
                        This field is required
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="phone">
                        Phone <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="phone"
                        type="phone"
                        className={`form-input w-full text-gray-300 
                        ${
                          this.state.inputToValidate.phone === false
                            ? ""
                            : this.state.inputToValidate.phone === true &&
                              this.state.contactform_validation.phone === false
                            ? "border-red-500"
                            : ""
                        } focus:border-blue-500 bg-black`}
                        placeholder="Enter your phone number"
                        required
                        value={this.state.phone}
                        onChange={this.onFormValueChange}
                      />
                      <p
                        className="text-red-500 text-sm mt-2"
                        hidden={
                          this.state.inputToValidate.phone === false
                            ? true
                            : this.state.contactform_validation.phone
                        }>
                        This field is required
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="email">
                        Email <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="email"
                        type="email"
                        className={`form-input w-full text-gray-300 
                        ${
                          this.state.inputToValidate.email === false
                            ? ""
                            : this.state.inputToValidate.email === true &&
                              this.state.contactform_validation.email === false
                            ? "border-red-500"
                            : ""
                        } focus:border-blue-500 bg-black`}
                        placeholder="Enter your email address"
                        required
                        value={this.state.email}
                        onChange={this.onFormValueChange}
                      />
                      <p
                        className="text-red-500 text-sm mt-2"
                        hidden={
                          this.state.inputToValidate.email === false
                            ? true
                            : this.state.contactform_validation.email
                        }>
                        This field is required
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="subject">
                        Subject <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="subject"
                        type="text"
                        className={`form-input w-full text-gray-300 
                        ${
                          this.state.inputToValidate.subject === false
                            ? ""
                            : this.state.inputToValidate.subject === true &&
                              this.state.contactform_validation.subject ===
                                false
                            ? "border-red-500"
                            : ""
                        } focus:border-blue-500 bg-black`}
                        placeholder="How can we help you?"
                        required
                        value={this.state.subject}
                        onChange={this.onFormValueChange}
                      />
                      <p
                        className="text-red-500 text-sm mt-2"
                        hidden={
                          this.state.inputToValidate.subject === false
                            ? true
                            : this.state.contactform_validation.subject
                        }>
                        This field is required
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="message">
                        Message <span className="text-red-600">*</span>
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        className={`form-input w-full text-gray-300 
                        ${
                          this.state.inputToValidate.message === false
                            ? ""
                            : this.state.inputToValidate.message === true &&
                              this.state.contactform_validation.message ===
                                false
                            ? "border-red-500"
                            : ""
                        } focus:border-blue-500 bg-black`}
                        placeholder="Write your message"
                        value={this.state.message}
                        onChange={this.onFormValueChange}></textarea>
                      <p
                        className="text-red-500 text-sm mt-2"
                        hidden={
                          this.state.inputToValidate.message === false
                            ? true
                            : this.state.contactform_validation.message
                        }>
                        This field is required
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label className="flex items-center">
                        <input
                          id="isAgreeChecked"
                          type="checkbox"
                          className={`form-checkbox 
                          ${
                            this.state.inputToValidate.isAgreeChecked === false
                              ? ""
                              : this.state.inputToValidate.isAgreeChecked ===
                                  true &&
                                this.state.contactform_validation
                                  .isAgreeChecked === false
                              ? "border-red-500"
                              : ""
                          } `}
                          checked={this.state.isAgreeChecked}
                          onChange={this.onFormValueChange}
                        />
                        <span className="text-gray-400 ml-2">
                          I agree to the privacy policy
                        </span>
                      </label>
                      <p
                        className="text-red-500 text-sm mt-2"
                        hidden={
                          this.state.inputToValidate.isAgreeChecked === false
                            ? true
                            : this.state.contactform_validation.isAgreeChecked
                        }>
                        This field is required
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button
                        className="btn text-white bg-blue-600 hover:bg-blue-700 w-full"
                        onClick={this.onSendButton}>
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>

          {/*  Bottom CTA */}
          <CtaContact />
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    );
  }
}

export default Contact;
