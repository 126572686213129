import React, { useState } from "react";
import Modal from "../utils/Modal";

function HeroHome() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        {/* Hero content */}
        <div className="relative  pb-10 md:pt-4 md:pb-4">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <p
              className="md:text-3xl lg:text-4xl text-2xl font-bold mb-4"
              data-aos="fade-up">
              Home Sales, Investments, Rentals and more
            </p>
            <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:hidden">
              {/* <div data-aos="fade-up" data-aos-delay="400">
                <a
                  className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
                  href="tel:(203)-973-7425"
                >
                  Call Us
                </a>
              </div> */}
              <div data-aos="fade-up" data-aos-delay="600">
                <a
                  className="btn text-white bg-blue-600 hover:bg-blue-700 w-full sm:w-auto sm:ml-4"
                  href="/contact">
                  Contact Us
                </a>
              </div>
              {/* <div data-aos="fade-up" data-aos-delay="600">
                <a
                  className="btn text-white bg-gray-700 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                  href="/products">
                  Learn more
                </a>
              </div> */}
            </div>
            <p
              className="text-2xl  mb-2"
              data-aos="fade-up"
              data-aos-delay="200">
              Erick D. Hernandez
            </p>
            <p
              className="text-xl text-gray-300 mb-2"
              data-aos="fade-up"
              data-aos-delay="200">
              Licensed Real Estate Agent
            </p>
            <p
              className="text-xl text-gray-300 mb-2"
              data-aos="fade-up"
              data-aos-delay="200">
              <a href="tel:+1(732)-998-6443">(732)-998-6443</a>
            </p>
            <p
              className="text-xl text-gray-300 mb-8"
              data-aos="fade-up"
              data-aos-delay="200">
              1220 Boulevard Seaside Heights NJ 08751
            </p>
            <div className="max-w-xs mx-auto sm:max-w-none md:flex sm:justify-center hidden">
              {/* <div data-aos="fade-up" data-aos-delay="400">
                <a
                  className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
                  href="tel:(203)-973-7425"
                >
                  Call Us
                </a>
              </div> */}
              <div data-aos="fade-up" data-aos-delay="600">
                <a
                  className="btn text-white bg-blue-600 hover:bg-blue-700 w-full sm:w-auto sm:ml-4"
                  href="/contact">
                  Contact Us
                </a>
              </div>
              {/* <div data-aos="fade-up" data-aos-delay="600">
                <a
                  className="btn text-white bg-gray-700 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                  href="/products">
                  Learn more
                </a>
              </div> */}
            </div>
          </div>

          {/* Hero image */}
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
