import React, { Component, useEffect } from "react";
import Client01 from "../images/client-01.svg";
import Client02 from "../images/client-02.svg";
import Client03 from "../images/client-03.svg";
import Client04 from "../images/client-04.svg";
import Client05 from "../images/client-05.svg";
import Client06 from "../images/client-06.svg";
import Client07 from "../images/client-07.svg";
import Client08 from "../images/client-08.svg";
import Client09 from "../images/client-09.svg";
import Client10 from "../images/client-10.svg";

import axios from "axios";

// Import Swiper
import Swiper, { Autoplay } from "swiper";
import "swiper/swiper.scss";
import { render } from "@testing-library/react";
Swiper.use([Autoplay]);

function FeaturesHome() {
  const [images, loadImages] = React.useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars

    const carousel = new Swiper(".clients-carousel", {
      slidesPerView: "auto",
      spaceBetween: 16,
      centeredSlides: true,
      loop: true,
      speed: 5000,
      noSwiping: false,
      noSwipingClass: "swiper-slide",
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
    });

    axios({
      method: "post",
      url:
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/realtor/v1/imageget"
          : "https://apiv1.reserv4me.com/realtor/v1/imageget",
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
    }).then((result) => {
      console.log(
        "🚀 ~ file: FeaturesHome.js:53 ~ useEffect ~ result",
        result.data
      );
      loadImages((current) => [...result.data]);
    });
  }, []);

  return (
    <section className="bg-blue-800 mt-8 ">
      <div className="py-6 md:py-6 ">
        {/* Section header */}
        <div className="max-w-3xl mx-auto text-center pt-8 pb-2 ">
          <h2 className="h4 font-cabinet-grotesk text-gray-100">
            Featured Listings
          </h2>
        </div>

        {/* Carousel built with Swiper.js [https://swiperjs.com/] */}
        {/* * Custom styles in src/css/additional-styles/theme.scss */}
        <div className="clients-carousel swiper-container relative before:absolute before:inset-0 before:w-32 before:z-10 before:pointer-events-none before:bg-gradient-to-r md:before:from-gray-900 after:absolute after:inset-0 after:left-auto after:w-32 after:z-10 after:pointer-events-none after:bg-gradient-to-l md:after:from-gray-900">
          <div className="swiper-wrapper !ease-linear select-none">
            {/* Carousel items */}

            {images[0] !== undefined &&
              images.map((current, index) => {
                console.log(current);
                return (
                  <div className="swiper-slide bg-blue-600 !h-96 !w-96  rounded-2xl flex items-center justify-center group overflow-hidden">
                    <img
                      className="   transform duration-500 ease-in-out"
                      src={current.StandardFields.Photos[0].UriLarge}
                      alt={`Listing${index}`}
                    />
                    <p className="absolute bg-black text-2xl bottom-14 right-2 rounded-xl p-0.5 truncate  text-left">
                      {`${current.StandardFields.StreetNumber} ${current.StandardFields.StreetName} ${current.StandardFields.City}`}
                    </p>
                    <p className="absolute bg-black text-2xl bottom-2 right-2 rounded-xl p-0.5">
                      {`${current.StandardFields.ListPrice.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                        }
                      )}`}
                    </p>
                  </div>
                );
              })}
            <div className="swiper-slide "></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesHome;
{
  // <section>
  //       <div className="max-w-6xl mx-auto px-4 sm:px-6">
  //         <div className="py-12 md:py-20 border-t border-white">
  //           {/* Items */}
  //           <div className="grid gap-20" data-aos-id-features-home>
  //             {/* Item */}
  //             <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
  //               {/* Image */}
  //               <div
  //                 className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
  //                 data-aos="fade-up"
  //                 data-aos-anchor="[data-aos-id-features-home]">
  //                 <div className="relative">
  //                   <img
  //                     className="md:max-w-none"
  //                     src="https://res.cloudinary.com/dykrzfpex/image/upload/v1639547135/Engsys-display_fs3afq.png"
  //                     width="540"
  //                     height="520"
  //                     alt="Features illustration"
  //                   />
  //                 </div>
  //               </div>
  //               {/* Content */}
  //               <div
  //                 className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
  //                 data-aos="fade-right"
  //                 data-aos-anchor="[data-aos-id-features-home]">
  //                 <div className="md:pr-4 lg:pr-12 xl:pr-16">
  //                   {/*<div className="font-architects-daughter text-xl text-blue-700 mb-2">
  //                     Modern Technologies to speed delivery
  //   </div>*/}
  //                   <h3 className="h3 mb-3">
  //                     Modern Technologies to speed delivery
  //                   </h3>
  //                   <p className="text-xl text-gray-400 mb-4">
  //                     We use modern Frameworks based on open source code to speed
  //                     the development of new ideas, technologies used by the big
  //                     playes in the industry
  //                   </p>
  //                   <ul className="flex flex-wrap text-lg text-gray-400 -mx-2 -my-1">
  //                     <li className="flex items-center mx-2 my-1">
  //                       <svg
  //                         className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
  //                         viewBox="0 0 12 12"
  //                         xmlns="http://www.w3.org/2000/svg">
  //                         <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
  //                       </svg>
  //                       <span>ReactJS</span>
  //                     </li>
  //                     <li className="flex items-center mx-2 my-1">
  //                       <svg
  //                         className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
  //                         viewBox="0 0 12 12"
  //                         xmlns="http://www.w3.org/2000/svg">
  //                         <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
  //                       </svg>
  //                       <span>NodeJS</span>
  //                     </li>
  //                     <li className="flex items-center mx-2 my-1">
  //                       <svg
  //                         className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
  //                         viewBox="0 0 12 12"
  //                         xmlns="http://www.w3.org/2000/svg">
  //                         <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
  //                       </svg>
  //                       <span>ExpressJS</span>
  //                     </li>
  //                     <li className="flex items-center mx-2 my-1">
  //                       <svg
  //                         className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
  //                         viewBox="0 0 12 12"
  //                         xmlns="http://www.w3.org/2000/svg">
  //                         <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
  //                       </svg>
  //                       <span>ElectronJS</span>
  //                     </li>
  //                     <li className="flex items-center mx-2 my-1">
  //                       <svg
  //                         className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
  //                         viewBox="0 0 12 12"
  //                         xmlns="http://www.w3.org/2000/svg">
  //                         <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
  //                       </svg>
  //                       <span>Javascript</span>
  //                     </li>
  //                     <li className="flex items-center mx-2 my-1">
  //                       <svg
  //                         className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
  //                         viewBox="0 0 12 12"
  //                         xmlns="http://www.w3.org/2000/svg">
  //                         <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
  //                       </svg>
  //                       <span>HTML</span>
  //                     </li>
  //                     <li className="flex items-center mx-2 my-1">
  //                       <svg
  //                         className="w-3 h-3 fill-current text-green-500 mr-2 flex-shrink-0"
  //                         viewBox="0 0 12 12"
  //                         xmlns="http://www.w3.org/2000/svg">
  //                         <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
  //                       </svg>
  //                       <span>CSS/Bootstrap/TailwindCSS</span>
  //                     </li>
  //                   </ul>
  //                   <div className="flex items-start mt-8">
  //                     <img
  //                       className="rounded-full flex-shrink-0 mr-4"
  //                       src="https://res.cloudinary.com/dykrzfpex/image/upload/v1639531873/ehfb_ctdfth.jpg"
  //                       width="40"
  //                       height="40"
  //                       alt="Features avatar"
  //                     />
  //                     <div>
  //                       <blockquote className="text-gray-400 italic mb-3">
  //                         "Where there is a will, there is a way".
  //                       </blockquote>
  //                       <div className="text-gray-700 font-medium">
  //                         <cite className="text-gray-200 not-italic">
  //                           — Erick Hernandez
  //                         </cite>{" "}
  //                         -{" "}
  //                         <a
  //                           className="text-blue-700 hover:text-gray-200 transition duration-150 ease-in-out"
  //                           href="#0">
  //                           CEO
  //                         </a>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
}
